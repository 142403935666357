



































































import { Component, Emit, Vue } from "vue-property-decorator";
import * as cls from "@/tool/_class";
import { ExamProject, ExamProjectSchool } from "@/classes/exam-project";
import * as _ from "lodash";
import { getExamProjectList } from "@/api/examProject";
import * as api from "@/api/scoreReport";

class FormData {
  examProjectId: string = "";
  clearOldData: boolean = false;
  examScoreProject: api.ExamScoreProject = new api.ExamScoreProject();
}
@Component({
  name: "ImportExamScoreDialog",
  components: {},
})
export default class extends Vue {
  @Emit("handleConfirmed") private handleConfirmed(
    params: api.ExamScoreProject,
    data: ExamProject,
    clearOldData: boolean
  ) {}

  private async __init() {
    await this.__init_examProjectList();
  }

  private examTypes: string[] = [];
  private examTypeOptions: { label: string; value: string }[] = [
    {
      label: "考试",
      value: "formalExamMode",
    },
    {
      label: "模拟",
      value: "mockExamMode",
    },
  ];

  private dialogVisible: boolean = false;
  private form: FormData = new FormData();

  private examProjectList: ExamProject[] = [];
  private examType: string = "";
  private query = {
    examStartTimeWithinDays: 90,
    examSchoolId: "",
  };
  private rules = {
    examProjectId: [
      { required: true, message: "请输入项目名", trigger: "blur" },
    ],
  };
  private filterExamProjectList(): ExamProject[] {
    return this.examProjectList.filter((item) => {
      if (this.examTypes.length == 0) {
        return true;
      }
      const examType: string = item.examType ? item.examType : "formalExamMode";
      const idx = this.examTypes.findIndex((item: string) => {
        return item == examType;
      });
      return idx >= 0;
    });
  }
  private async __init_examProjectList() {
    try {
      this.examProjectList.splice(0, this.examProjectList.length);
      const res = await getExamProjectList({
        examType: this.examType,
        pageSize: 999,
        curPage: 1,
        examStartTimeWithinDays: this.query.examStartTimeWithinDays,
        examSchoolId: this.query.examSchoolId || "",
      });

      this.examProjectList = res.data.items.map((item: any) => {
        return _.merge(item, {
          value: item.examProjectId,
          label: item.examProjectName,
        });
      });
    } finally {
      const examProject = this.examProjectList.find((item) => {
        return item.examProjectId == this.form.examProjectId;
      });
      if (!examProject) {
        this.form.examProjectId = "";
      }
    }
  }

  async show(params: api.ExamScoreProject) {
    this.form = new FormData();
    _.merge(this.form.examScoreProject, params);
    await this.__init();
    this.dialogVisible = true;
  }

  private async doOkClick() {
    await (this.$refs.ruleForm as any).validate();
    //
    const idx = this.examProjectList.findIndex((item) => {
      return item.examProjectId == this.form.examProjectId;
    });
    if (idx < 0) {
      return;
    }
    this.handleConfirmed(
      this.form.examScoreProject,
      this.examProjectList[idx],
      this.form.clearOldData
    );
    this.dialogVisible = false;
  }
}
