






















































































































































import { Component, Vue } from "vue-property-decorator";
import CardBox from "@/components/card-box/index.vue";
import * as api from "@/api/scoreReport";
import { PageNum } from "@/tool/interface-index";
import { ExamProject, ExamProjectSchool } from "@/classes/exam-project";
import Pagination from "@/components/pagination/index.vue";
import { UserModule } from "@/store/modules/user";
import * as ResApi from "@/api/examFileResource";
import * as CmmApi from "@/api/examComment";
import ImportExamScoreDialog from "@/views/score-report-platform/import-exam-score-dialog.vue";
import UploadExamScoreDialog from "@/views/score-report-platform/upload-exam-score-dialog.vue";
import * as _ from "lodash";
import uploadExamScoreDialogVue from "./upload-exam-score-dialog.vue";

@Component({
  name: "ScoreProjectListPage",
  components: {
    CardBox,
    Pagination,
    ImportExamScoreDialog,
    UploadExamScoreDialog,
  },
})
export default class extends Vue {
  private query: {
    examProjectName: string;
    keyWords: string;
  } = {
    examProjectName: "",
    keyWords: "",
  };
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };

  private fileList: any[] = [];
  private headers = {
    Authorization: `bearer ${UserModule.token}`,
  };
  private handleRemove(file: any, fileList: any[]) {
    console.log(file, fileList);
  }
  private handlePreview(file: any) {
    console.log(file);
  }
  private handleExceed(files: any[], fileList: any[]) {
    console.log(`files:${JSON.stringify(files)}`);
    //alert("test");
    //fileList.splice(0, fileList.length);
    //this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
  }
  beforeRemove(file: any, fileList: any[]) {
    return this.$confirm(`确定移除 ${file.name}？`);
  }

  private handleUploadSuccess(response: any, file: any, fileList: any[]) {
    console.log(
      `file: ${JSON.stringify(file)}, fileList: ${JSON.stringify(fileList)}`
    );
    const idx = fileList.findIndex((item) => {
      return file.uid == item.uid;
    });
    if (idx >= 0) {
      fileList.splice(idx, 1);
      console.log(`this.fileList: ${JSON.stringify(this.fileList)}`);
    }
    this.$message({
      type: "success",
      message: `上传“${file.name}”,成功!`,
    });
  }

  private handleUploadError(err: any, file: any, fileList: any[]) {
    const idx = fileList.findIndex((item) => {
      return file.uid == item.uid;
    });
    if (idx >= 0) {
      fileList.splice(idx, 1);
      console.log(`this.fileList: ${JSON.stringify(this.fileList)}`);
    }
    this.$message({
      type: "error",
      message: `上传“${file.name}”,失败!`,
    });
  }

  private uploading: boolean = false;
  private handleUploadRequest(params: any, project: api.ExamScoreProject) {
    //alert(JSON.stringify(params));
    const file = params.file;
    let formData = new FormData();
    formData.append("file01", file, "file01.xsjz"); //'file01.xsjz'/*this.file
    //formData.append(file.name, this.file, file.name);
    this.uploading = true;
    api
      .uploadScore(formData, {
        file01: file.name,
        examProjectId: project.examProjectId,
        examProjectName: project.examProjectName,
      })
      .then((res: any) => {
        //this.fileList.splice(0, this.fileList.length);
        params.onSuccess(res);
        /*
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "上传成功!",
          });
        }
        */
      })
      .catch((error) => {
        //this.fileList.splice(0, this.fileList.length);
        params.onError(error);
        //this.$message.error(`上传失败,${error.msg || error!.message || ""}`);
      })
      .finally(() => {
        this.uploading = this.fileList.length > 0;
      });
  }

  private tableData: Array<api.ExamScoreProject> = [];
  private loading: boolean = false;
  /*
  private async getExamProjectData() {
    try {
      this.loading = true;
      const res = await api.getExamProjectList({
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage,
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }
  */
  private async refPageList() {
    try {
      this.loading = true;
      const res = await api.getExamProjectList({
        examProjectIds: (this.$route.query.examProjectIds as string[]) || [],
        examProjectName: this.query!.examProjectName || "",
        keyWords: this.query!.keyWords || "",
        curPage: this.pageNum.curPage,
        pageSize: this.pageNum.pageSize,
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
      this.pageNum.curPage = res.data.curPage;
      this.pageNum.pageSize = res.data.pageSize;
    } finally {
      this.loading = false;
    }
  }

  private async deleteDataClick(examProjectId: string) {
    await this.$confirm("删除项目，是否继续？", "提示", {
      type: "warning",
    });
    await api.deleteExamProject(examProjectId);
    const idx = this.tableData.findIndex((examProject) => {
      return examProject.examProjectId == examProjectId;
    });
    this.tableData.splice(idx, 1);
  }

  private setExamClick(examProjectId: string, actionType: string) {
    this.$router.push({
      path: "scoreProjectEditor",
      query: {
        examProjectId,
        type: actionType,
      },
    });
  }

  private get uploadExamScoresApiUrl(): string {
    return "";
    return `${process.env.VUE_APP_BASE_API}/iScoreReport/v1/upload/importScore`;
  }

  private uploadExamScoreLoading: boolean = false;
  private async handleUploadDlgConfirmed(
    params: api.ExamScoreProject,
    clearOldData: boolean
  ) {
    try {
      this.uploadExamScoreLoading = true;
      //
    } finally {
      this.uploadExamScoreLoading = false;
    }
  }

  private importExamScoreLoading: boolean = false;
  private async handleImportDlgConfirmed(
    params: api.ExamScoreProject,
    examProject: ExamProject,
    clearOldData: boolean
  ) {
    try {
      this.importExamScoreLoading = true;

      const { data } = await CmmApi.getExamProjectScores({
        examProjectId: examProject.examProjectId,
      });
      if (params.examProjectId) {
        const idx = this.tableData.findIndex((item) => {
          return item.examProjectId == params.examProjectId;
        });
        if (idx >= 0) {
          _.merge(data, this.tableData[idx]);
        } else {
          throw new Error("统分项目匹配失败。");
        }
      } else {
        data.examProjectName = params.examProjectName || data.examProjectName;
      }
      await api.importScore(data, { clearOldData });
      this.refPageList();
    } catch (error: any) {
      this.$message({
        type: "error",
        message: `导入成绩失败, ${error.message || error.msg || ""}`,
      });
    } finally {
      this.importExamScoreLoading = false;
    }
  }

  private doQuickNewExamScoreProjectClick() {
    (this.$refs.importDlg as ImportExamScoreDialog).show(
      new api.ExamScoreProject()
    );
  }

  private async doImportExamScoresClick(examProject: api.ExamScoreProject) {
    (this.$refs.importDlg as ImportExamScoreDialog).show(examProject);
  }

  private async doUploadExamScoresClick(examProject: api.ExamScoreProject) {
    (this.$refs.uploadDlg as ImportExamScoreDialog).show(examProject);
  }

  private analyzeLoading: boolean = false;
  private doAnalyzeScoreClick(examProject: api.ExamScoreProject) {
    this.analyzeLoading = true;
    api
      .analyzeScore(
        {
          examProjectId: examProject.examProjectId,
          examProjectName: examProject.examProjectName,
          resports: [],
        },
        { overwrite: true }
      )
      .then((resp) => {})
      .catch((error) => {})
      .finally(() => {
        this.analyzeLoading = false;
      });
  }

  private downloading: boolean = false;
  private doDownloadAnalyzeScoreClick(examProject: api.ExamScoreProject) {
    const baseUrl: string = process.env.VUE_APP_BASE_API || "";
    this.downloading = true;
    ResApi.download(
      `${baseUrl}${
        baseUrl.endsWith("/") ? "" : "/"
      }iScoreReport/v1/download/analyzeScore?examProjectId=${
        examProject.examProjectId
      }`,
      {},
      `${examProject.examProjectName}统计报表.zip`
    )
      .then((resp) => {})
      .catch((error) => {
        this.$message({
          type: "error",
          message: `下载分析结果失败!`,
        });
      })
      .finally(() => {
        this.downloading = false;
      });
  }

  private upDataPage() {
    this.refPageList();
  }

  private doQueryClick() {
    this.refPageList();
  }

  private async __init() {}
  activated() {
    this.__init();
    this.refPageList();
  }
  mounted() {}
}
