import request from '@/utils/request';

const MaxTimeout = 300000;

export class ExamScoreProject {
    examProjectId: string = "";
    examProjectName: string = "";
    importScoreState: string = "";
    analyzeScoreState: string = "";
}

export const getExamProjectList = (params: {
    examProjectIds?: string[],
    examProjectId?: string,
    examSchoolId?: string,
    examProjectName?: string,
    keyWords?: string,
    pageSize: number,
    curPage: number
}) =>
    request({
        // 获取考试项目列表
        url: '/iScoreReport/v1/examProjectList',
        method: 'GET',
        params,

    })

export const postExamProject = (data: any) =>
    request({
        // 添加/更新考试项目列表
        url: '/iScoreReport/v1/examProject',
        method: 'POST',
        data
    })

export const getExamProject = (examProjectId: string) =>
    request({
        //  获取考试项目by examProjectId
        url: `/iScoreReport/v1/examProject/${examProjectId}`,
        method: 'GET'
    })

export const deleteExamProject = (examProjectId: string) =>
    request({
        // 删除考试项目by examProjectId
        url: `/iScoreReport/v1/examProject/${examProjectId}`,
        method: 'DELETE'
    })

export const importScore = (data: {
    examProjectId: string,
    examProjectName: string,
    examStudents: {
        examStudentId: string,
        examProjectId?: string,
        areaName: string,
        studentId: string,
        studentNo: string,
        studentName: string,
        examPaperName: string,
        schoolName: string,
        className: string,
        examPaperScore: number,
        scores: {
            examStudentScoreId: string,
            examProjectId?: string,
            studentId?: string,
            studentNo?: string,
            examPaperName?: string,
            scoreName: string,
            score: number,
            scoreType: string
        }[]
    }[]
}, params?: { clearOldData: boolean }) =>
    request({
        // 导入分数
        url: '/iScoreReport/v1/importScore',
        method: 'POST',
        data,
        timeout: MaxTimeout,
        params
    });

export const uploadScore = (data: any, params: any) => request({
    //导入成绩表等文件
    url: '/iScoreReport/v1/upload/importScore',
    method: 'POST',
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': 'zh-CN,zh;q=0.9',
    },
    data,
    params,
    timeout: MaxTimeout
});

export const analyzeScore = (data: {
    examProjectId: string,
    examProjectName?: string,
    resports: { name: string, index: number }[]
}, params: { overwrite?: boolean }) => request({
    // 分析报表
    url: '/iScoreReport/v1/analyzeScore',
    method: 'POST',
    data,
    params,
    timeout: MaxTimeout
});

export const downloadAnalyzeScore = (params: {
    examProjectId: string,
    examProjectName?: string,
    fileName: string
}) => request({
    //下载分析报表
    url: '/iScoreReport/v1/download/analyzeScore',
    method: 'GET',
    params
});

export const reports = (index: number, params: {
    examProjectId: string,
    examProjectName?: string,
}) => request({
    //报表
    url: `/iScoreReport/v1/reports/${index}`,
    method: 'GET',
    params
});