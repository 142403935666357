




































































import { Component, Emit, Vue } from "vue-property-decorator";
import * as cls from "@/tool/_class";
import { ExamProject, ExamProjectSchool } from "@/classes/exam-project";
import * as _ from "lodash";
import { getExamProjectList } from "@/api/examProject";
import * as api from "@/api/scoreReport";

class MyFormData {
  examProjectId: string = "";
  clearOldData: boolean = false;
  examScoreProject: api.ExamScoreProject = new api.ExamScoreProject();
}
@Component({
  name: "UploadExamScoreDialog",
  components: {},
})
export default class extends Vue {
  @Emit("handleConfirmed") private handleConfirmed(
    params: api.ExamScoreProject,

    clearOldData: boolean
  ) {}

  private async __init() {}

  private dialogVisible: boolean = false;
  private form: MyFormData = new MyFormData();
  private fileList: /*{ name: string; url: string }*/ any[] = [];
  private formData = new FormData();

  private rules = {};

  private async validate() {
    await (this.$refs.ruleForm as any).validate();
  }

  async show(params: api.ExamScoreProject) {
    this.form = new MyFormData();
    _.merge(this.form.examScoreProject, params);
    await this.__init();
    this.dialogVisible = true;
  }

  private async doOkClick() {
    await this.validate();
    //

    this.handleConfirmed(
      this.form.examScoreProject,

      this.form.clearOldData
    );
    this.dialogVisible = false;
  }
  //上传BEGIN
  private async submitUpload() {
    try {
      this.uploading = true;
      await this.validate();

      await (this.$refs.upload as any).submit();
      await api.uploadScore(this.formData, {
        //file01: file.name,
        examProjectId: this.form.examScoreProject.examProjectId,
        examProjectName: this.form.examScoreProject.examProjectName,
      });
      this.fileList.splice(0, this.fileList.length);
    } finally {
      this.uploading = false;
    }
  }

  private get uploadActionUrl(): string {
    return "";
    //return `${process.env.VUE_APP_BASE_API}/iScoreReport/v1/upload/importScore`;
  }

  private uploading: boolean = false;
  private handleUploadRequest(params: any) {
    const uploadFiles: any[] = (this.$refs.upload as any).uploadFiles;
    const file = params.file;
    let fileName: string = "";
    const idx = uploadFiles.findIndex((item) => {
      let result: boolean = false;
      result = item.raw.uid == file.uid;
      if (result) {
        fileName = item.name;
      }
      return result;
    });
    let ext: string = /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName)![0] as string: ""
    this.formData.append(`file0${idx + 1}`, file, `file0${idx + 1}${ext}`);
  }

  private handleRemove(file: any, fileList: any[]) {
    console.log(file, fileList);
  }
  private handlePreview(file: any) {
    console.log(file);
  }
  //上传END
}
